
import React, {useState, useEffect} from "react"
import Layout from "../../components/layout"

import WebappForm from "../../components/webapps/form"
import SubmitDataField from "../../components/negosyonow/fields/submitdata"


const negosyonowAPI = require("../../../lib/requestNegosyownow")
const webappsAPI = require("../../../lib/requestWebapps")

const ENTITYID="nncourseuser"


const searchFields = [
	{"label":"Date Registered", "dbfield": "nncourseuser_registered", "type": "datetime", "filtertype": "datetime"},
	{"label":"Course Registration ID", "dbfield": "nncourseuser_regid", "type": "integer", "filtertype": "integer"},
	//{"label":"Registrant ID#", "dbfield": "onsperson_idnum", "type": "text", "filtertype": "text"},
	{"label":"Resgistrant Name", "dbfield": "onsperson_name", "type": "text", "filtertype": "text"},
	{"label":"Course", "dbfield": "nncourse_name", "type": "text", "filtertype": "text"},
	{"label":"Mobile #", "dbfield": "nncourseuser_mobilenum", "type": "text", "filtertype": "text"},
	{"label":"Email", "dbfield": "nncourseuser_email", "type": "text", "filtertype": "text"},
	{"label":"Course Fees", "dbfield": "nncourse_regfee", "type": "currency", "filtertype": "currency"},
	{"label":"Amount Paid", "dbfield": "nncourseuser_paidamount", "type": "currency", "filtertype": "currency"},
	{"label":"Registered", "dbfield": "nncourseuser_confirmed", "type": "checkbox", "filtertype": "checkbox"},
	{"label":"Status", "dbfield": "nnusercontactstatus_name", "type": "text", "filtertype": "textbox"},
	{"label":"Expiration", "dbfield": "nncourseuser_expirationdate", "type": "date", "filtertype": "date"},
];
//

const formFields = [
	[
		{"label":"Date Registered", "field": "nncourseuser_registered", "value": "", "input": "datetime", "mode": "readonly"},
		{"label":"Course Registration ID", "field": "nncourseuser_regid", "value":"", "input": "integer", "mode": "readonly", "info":"For use as reference # in payments"},
		{"label":"ID #", "field": "onsperson_idnum", "value": "", "input": "picker", "mode": "readonly"},
		{"label":"First Name", "field": "onsperson_firstname", "value": "", "input": "text", "mode": "readonly"},
		{"label":"Last Name", "field": "onsperson_lastname", "value": "", "input": "text", "mode": "readonly"},
		{"label":"Course", "field": "nncourse_name", "value": "", "input": "picker", "mode": "readonly"},
		{"label":"Mobile #", "field": "nncourseuser_mobilenum", "value": "", "input": "textbox", "mode": "readonly"},
		{"label":"email", "field": "nncourseuser_email", "value": "", "input": "textbox", "mode": "readonly"},
		{"label":"Details", "field": "nncourseuser_submiturl", "value": "", "input": "textarea", "mode": "readonly"},
		{"label":"Course Fees", "field": "nncourse_regfee", "value": "", "input": "currency", "mode": "readonly"},
		{"label":"Amount Paid", "field": "nncourseuser_paidamount", "value": "", "input": "currency", "mode": "readonly"},
		{"label":"Status", "field": "nnusercontactstatus_id", "value": "", "input": "combo", "mode": "required", "options": [
			{"value":1,"display":"New"},
			{"value":2,"display":"Contacted"},
			{"value":3,"display":"Going"},
			{"value":4,"display":"Not Going"},
			{"value":5,"display":"Cannot be reached"},
		]},
		{"label":"Allow Payment", "field": "nncourseuser_allowpayment", "value": "", "input": "checkbox", "mode": "normal"},
		{"label":"Registered", "field": "nncourseuser_confirmed", "value": "", "input": "checkbox", "mode": "readonly"},
		{"label":"Expiration", "field": "nncourseuser_expirationdate", "value": "", "input": "date", "mode": "readonly", "info":"Based on validity from start of use"},

		{"label":"Person ID", "field": "onsperson_id", "value": "", "input": "hidden", "mode": "readonly"},
		{"label":"Course ID", "field": "nncourse_id", "value": "", "input": "hidden", "mode": "readonly"},
	]
];




const CourseuserPage = ({location}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");
	const [userformurl, setUserformurl] = useState("");
	const [userformdata, setUserformdata] = useState({});

	const [docid, setDocid] = useState(0);
	const [onspersonid, setOnspersonid] = useState(0);

	function customSubmit(entity, params, payloadlist, subformpayloadlist, token, callback)
	{
		//callback({"status":"Error", "message":"Debug"}); return;
		if (docid < 1) {
			callback({"status":"Error", "message":"Unable to process data, please try again"});
			return;
		}

		webappsAPI.savePayload(entity, params, token, payloadlist, subformpayloadlist, function(response){
			if (response.status === "OK") {
				negosyonowAPI.buildEntitybyParam({"id":docid, "mode": "user"},token, "course").then(nnresponse => {
					negosyonowAPI.buildEntitybyParam({"id":onspersonid, "mode": "exclusivelist"},token, "userprofile").then(nnuserresponse => {
						callback(response);
					});
				});
				return;
			}
			callback(response);
		}); // save record

	}

	function customValidateValues(mainform, subformlist, footerformlist)
	{
		var idx = 0;
		while (idx < mainform.length) {
			if (mainform[idx].field === "nncourse_id") {
				setDocid(mainform[idx].value);
			} else if (mainform[idx].field === "onsperson_id") {
				setOnspersonid(mainform[idx].value);
			}
			idx++;
		}
		return {"status":"OK"};
	}

	useEffect(() => {
		function loadFormdata(formurl)
		{
			negosyonowAPI.loadFormData(formurl, token).then(dataresponse => {
				setUserformdata(dataresponse);
			});
		}

		loadFormdata(userformurl)
	}, [userformurl, token]);

	function customEditFieldInfo(inputlist, idx)
	{
		const fieldlist = ["nncourseuser_allowpayment"];
		if (!fieldlist.includes(inputlist[idx].field)) {
			return inputlist[idx];
		}
		var tmpidx = 0;

		while (tmpidx < inputlist.length) {
			if (inputlist[tmpidx].field === "nncourse_regfee") {
				if (inputlist[tmpidx].value !== "") {
					if (parseFloat(inputlist[tmpidx].value) > 0) {
						break;
					}
				}
				var tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
				tmpfield.mode = "readonly";
				return tmpfield;
			}
			tmpidx++;
		}

		// Fee not found
		return inputlist[idx];
	} // customEditFieldInfo

	function customFieldFragment(inputlist, idx, isdisabled, fieldid, inputchangecallback)
	{
		if (inputlist[idx].field === "nncourseuser_submiturl") {
			if (inputlist[idx].value) {
				if (inputlist[idx].value !== "") {
					setUserformurl(inputlist[idx].value);
					return displayFormData(inputlist[idx].label, userformdata);
				}
			} else {
				return <></>
			}
		}
		return null;
	}

	function displayFormData(title,formdata)
	{
		const labelformatlist = {
			"Date of Birth":"date"
		};
		return <SubmitDataField
					datatitle={title}
					formdata={formdata}
					labelformatlist={labelformatlist}
				/>
	}


	return <Layout location={location}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			privatePage={true} usegatedcontent={true}
		>
			<WebappForm
				pagetitle={pagetitle}
				allowdownload={true}
				allowadd={false}
				allowdelete={false}
				mobilerowtitlefield={["onsperson_name"]}
				searchFields={searchFields}
				editFields={formFields}
				userSearchParam={{"orderby":"nncourseuser_registered desc"}}

				customSubmit={customSubmit}
				customFieldFragment={customFieldFragment}
				customEditFieldInfo={customEditFieldInfo}
				customValidateValues={customValidateValues}
				entity={ENTITYID}
				token={token} />
		</Layout>

}


export default CourseuserPage;
